var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "root" } },
    [
      _c(
        "ais-instant-search",
        {
          attrs: {
            "search-client": _vm.searchClient,
            "index-name": "dev_users",
            routing: _vm.routing
          }
        },
        [
          _c("ais-configure", {
            attrs: {
              attributesToSnippet: ["description:10"],
              snippetEllipsisText: "…",
              removeWordsIfNoResults: "allOptional"
            }
          }),
          _vm._v(" "),
          _c(
            "header",
            {
              staticClass:
                "header bg-holder bg-overlay-i justify-content-center text-center pt-3 pt-md-4 pb-9 mb-0",
              attrs: { id: "header" }
            },
            [
              _c("div", { staticClass: "searchbar-collapse-header" }, [
                _c("div", { staticClass: "row mt-0 mt-md-4" }, [
                  _c(
                    "div",
                    { staticClass: "col-6 collapse-brand pl-md-6 text-left" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "ml-3",
                          attrs: {
                            href: "#searchbar_global",
                            "data-toggle": "collapse",
                            "data-target": "#searchbar_global",
                            "aria-controls": "searchbar_global",
                            "aria-expanded": "false",
                            "aria-label": "Toggle navigation"
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "img-fluid logo-index",
                            staticStyle: { height: "auto!important" },
                            attrs: {
                              src:
                                "https://nimble.careers/assets/img/logo/nimble-logo-round.png",
                              width: "40"
                            }
                          })
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6 collapse-close pr-md-6" }, [
                    _c("a", {
                      staticClass: "fal fa-times fs-3 text-warning mr-3",
                      attrs: {
                        href: "#searchbar_global",
                        "data-toggle": "collapse",
                        "data-target": "#searchbar_global",
                        "aria-controls": "searchbar_global",
                        "aria-expanded": "false",
                        "aria-label": "Toggle navigation"
                      }
                    })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-center w-100" },
                [
                  _c(
                    "ais-search-box",
                    { attrs: { placeholder: "Type «John Doe»" } },
                    [
                      _c("template", { slot: "submit-icon" }, [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 18 18",
                              width: "16",
                              height: "16"
                            }
                          },
                          [
                            _c(
                              "g",
                              {
                                attrs: {
                                  fill: "none",
                                  "fill-rule": "evenodd",
                                  stroke: "currentColor",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "1.67",
                                  transform: "translate(1 1)"
                                }
                              },
                              [
                                _c("circle", {
                                  attrs: { cx: "7.11", cy: "7.11", r: "7.11" }
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: { d: "M16 16l-3.87-3.87" }
                                })
                              ]
                            )
                          ]
                        )
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("main", { staticClass: "container" }, [
            _c("div", { staticClass: "container-wrapper" }, [
              _c("section", { staticClass: "container-filters pt-0" }, [
                _c(
                  "div",
                  { staticClass: "container-header" },
                  [
                    _c("h2", [_vm._v("Filters")]),
                    _vm._v(" "),
                    _c(
                      "ais-clear-refinements",
                      { attrs: { "data-layout": "desktop" } },
                      [
                        _c("template", { slot: "resetLabel" }, [
                          _c("div", { staticClass: "clear-filters" }, [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "10",
                                  height: "10",
                                  viewBox: "0 0 11 11"
                                }
                              },
                              [
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      fill: "none",
                                      "fill-rule": "evenodd",
                                      opacity: ".4"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      attrs: { d: "M0 0h11v11H0z" }
                                    }),
                                    _vm._v(" "),
                                    _c("path", {
                                      attrs: {
                                        fill: "#000",
                                        "fill-rule": "nonzero",
                                        d:
                                          "M8.26 2.75a3.896 3.896 0 1 0 1.102 3.262l.007-.056a.49.49 0 0 1 .485-.456c.253 0 .451.206.437.457 0 0 .012-.109-.006.061a4.813 4.813 0 1 1-1.348-3.887v-.987a.458.458 0 1 1 .917.002v2.062a.459.459 0 0 1-.459.459H7.334a.458.458 0 1 1-.002-.917h.928z"
                                      }
                                    })
                                  ]
                                )
                              ]
                            ),
                            _vm._v(
                              "\n                  Clear filters\n                "
                            )
                          ])
                        ])
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("ais-stats", {
                      attrs: { "data-layout": "mobile" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var nbHits = ref.nbHits
                            return [
                              _c("span", { staticClass: "ais-Stats-text" }, [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.formatNumber(nbHits)))
                                ]),
                                _vm._v(" results\n                ")
                              ])
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "container-body" },
                  [
                    _c(
                      "ais-panel",
                      [
                        _c("template", { slot: "header" }, [
                          _vm._v("Career Availability")
                        ]),
                        _vm._v(" "),
                        _c(
                          "template",
                          { slot: "default" },
                          [
                            _c("ais-refinement-list", {
                              attrs: {
                                attribute:
                                  "jobPreference.jobAvailability.shortLabel"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "ais-panel",
                      [
                        _c("template", { slot: "header" }, [
                          _vm._v("Career Industry")
                        ]),
                        _vm._v(" "),
                        _c(
                          "template",
                          { slot: "default" },
                          [
                            _c("ais-refinement-list", {
                              attrs: {
                                attribute: "jobPreference.jobField.label",
                                searchable: "",
                                searchablePlaceholder: "Search for Industry"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "ais-panel",
                      [
                        _c("template", { slot: "header" }, [
                          _vm._v("Job title")
                        ]),
                        _vm._v(" "),
                        _c(
                          "template",
                          { slot: "default" },
                          [
                            _c("ais-refinement-list", {
                              attrs: {
                                attribute: "jobPreference.jobTitle.label",
                                searchable: "",
                                searchablePlaceholder: "Search for title"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "ais-panel",
                      [
                        _c("template", { slot: "header" }, [
                          _vm._v("Contract Types")
                        ]),
                        _vm._v(" "),
                        _c(
                          "template",
                          { slot: "default" },
                          [
                            _c("ais-refinement-list", {
                              attrs: {
                                attribute: "jobPreference.jobType.label",
                                searchable: "",
                                searchablePlaceholder:
                                  "Search for contract type"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "ais-panel",
                      [
                        _c("template", { slot: "header" }, [
                          _vm._v("Preferred Location")
                        ]),
                        _vm._v(" "),
                        _c(
                          "template",
                          { slot: "default" },
                          [
                            _c("ais-refinement-list", {
                              attrs: {
                                attribute: "jobPreference.jobLocation.label",
                                searchable: "",
                                searchablePlaceholder: "Search for location"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "ais-panel",
                      [
                        _c("template", { slot: "header" }, [_vm._v("Schools")]),
                        _vm._v(" "),
                        _c(
                          "template",
                          { slot: "default" },
                          [
                            _c("ais-refinement-list", {
                              attrs: {
                                attribute: "graduateUser.school.name",
                                searchable: "",
                                searchablePlaceholder: "Search for school…"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "ais-panel",
                      [
                        _c("template", { slot: "header" }, [
                          _vm._v("Fields of study")
                        ]),
                        _vm._v(" "),
                        _c(
                          "template",
                          { slot: "default" },
                          [
                            _c("ais-refinement-list", {
                              attrs: {
                                attribute: "graduateUser.degreeFields.label",
                                searchable: "",
                                searchablePlaceholder: "Search for fields…"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "ais-panel",
                      [
                        _c("template", { slot: "header" }, [
                          _vm._v("Curriculum Title")
                        ]),
                        _vm._v(" "),
                        _c(
                          "template",
                          { slot: "default" },
                          [
                            _c("ais-refinement-list", {
                              attrs: {
                                attribute:
                                  "graduateUser.degrees.degreeTitle.label",
                                searchable: "",
                                searchablePlaceholder: "Search for curriculum"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("template", { slot: "header" }, [
                      _vm._v("Year-end Graduation")
                    ]),
                    _vm._v(" "),
                    _c(
                      "template",
                      { slot: "default" },
                      [
                        _c("ais-range-input", {
                          attrs: {
                            attribute: "graduateUser.issuances.algoliasYearEnd"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var currentRefinement = ref.currentRefinement
                                var range = ref.range
                                var refine = ref.refine
                                return _c(
                                  "div",
                                  {},
                                  [
                                    _c("vue-slider", {
                                      attrs: {
                                        min: range.min,
                                        max: range.max,
                                        lazy: true,
                                        value: _vm.toValue(
                                          currentRefinement,
                                          range
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return refine({
                                            min: $event[0],
                                            max: $event[1]
                                          })
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              }
                            }
                          ])
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "ais-panel",
                      [
                        _c("template", { slot: "header" }, [_vm._v("Honors")]),
                        _vm._v(" "),
                        _c(
                          "template",
                          { slot: "default" },
                          [
                            _c("ais-refinement-list", {
                              attrs: { attribute: "graduateUser.degrees.honor" }
                            })
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c(
                "footer",
                {
                  staticClass: "container-filters-footer",
                  attrs: { "data-layout": "mobile" }
                },
                [
                  _c("clear-refinements", {
                    staticClass: "container-filters-footer-button-wrapper",
                    on: { click: _vm.closeFilters }
                  }),
                  _vm._v(" "),
                  _c("ais-stats", {
                    staticClass: "container-filters-footer-button-wrapper",
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var nbHits = ref.nbHits
                          return [
                            _c(
                              "button",
                              {
                                staticClass: "button button-primary",
                                on: { click: _vm.closeFilters }
                              },
                              [
                                _vm._v(
                                  "\n                See " +
                                    _vm._s(_vm.formatNumber(nbHits)) +
                                    " results\n              "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "section",
              { staticClass: "container-results pt-0" },
              [
                _c(
                  "header",
                  { staticClass: "container-header container-options mr-3" },
                  [
                    _c("ais-hits-per-page", {
                      staticClass: "container-option text-primary",
                      attrs: {
                        items: [
                          {
                            label: "16 alumni per page",
                            value: 16,
                            default:
                              _vm.getSelectedHitsPerPageValue() === 16 ||
                              !_vm.getSelectedHitsPerPageValue()
                          },
                          {
                            label: "32 alumni per page",
                            value: 32,
                            default: _vm.getSelectedHitsPerPageValue() === 32
                          },
                          {
                            label: "64 alumni per page",
                            value: 64,
                            default: _vm.getSelectedHitsPerPageValue() === 64
                          }
                        ]
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("ais-hits", {
                  scopedSlots: _vm._u([
                    {
                      key: "item",
                      fn: function(ref) {
                        var item = ref.item
                        return _c("div", {}, [
                          _c(
                            "div",
                            {
                              staticClass: "card mt-0 mr-0 ml-0 py-2",
                              staticStyle: { "min-height": "170px" }
                            },
                            [
                              _c(
                                "a",
                                { attrs: { href: "/" + item.objectID } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "card-body justify-content-between w-100"
                                    },
                                    [
                                      _c("div", { staticClass: "row pt-2" }, [
                                        _c("div", { staticClass: "col-4" }, [
                                          item.picture.length < 2
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "img-fluid rounded mb-2 img-profile mt-2 bg-warning align-items-center justify-content-center d-flex square h-100"
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-center text-white fs-4"
                                                    },
                                                    [
                                                      _c("ais-highlight", {
                                                        attrs: {
                                                          attribute: "picture",
                                                          hit: item
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            : _c("img", {
                                                staticClass:
                                                  "hit-image rounded",
                                                attrs: {
                                                  src:
                                                    "https://app.nimble.careers/uploads/picture/" +
                                                    item.picture,
                                                  alt: item.name
                                                }
                                              })
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col-8" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "hit-info-container"
                                            },
                                            [
                                              _c(
                                                "h5",
                                                {
                                                  staticClass:
                                                    "text-secondary font-weight-bold"
                                                },
                                                [
                                                  _c("ais-highlight", {
                                                    attrs: {
                                                      attribute: "firstname",
                                                      hit: item
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("ais-highlight", {
                                                    attrs: {
                                                      attribute: "lastname",
                                                      hit: item
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "text-muted" },
                                                [
                                                  _c("ais-highlight", {
                                                    attrs: {
                                                      attribute:
                                                        "graduateUser.degrees.degreeTitle.label[0]",
                                                      hit: item
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "mt-1" },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fal fa-map-marker text-warning font-weight-normal"
                                                  }),
                                                  _vm._v(" "),
                                                  _c("ais-highlight", {
                                                    attrs: {
                                                      attribute: "location",
                                                      hit: item
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "font-weight-bold mt-2"
                                                },
                                                [
                                                  _c("ais-highlight", {
                                                    attrs: {
                                                      attribute:
                                                        "careerField.label",
                                                      hit: item
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "badge badge-primary"
                                                    },
                                                    [
                                                      _c("ais-highlight", {
                                                        attrs: {
                                                          attribute:
                                                            "jobPreference.jobAvailability.shortLabel",
                                                          hit: item
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        _c("div", { staticClass: "col-12" })
                                      ])
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("no-results"),
                _vm._v(" "),
                _c(
                  "footer",
                  { staticClass: "container-footer" },
                  [
                    _c("ais-pagination", {
                      attrs: { padding: 2 },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var currentRefinement = ref.currentRefinement
                            var nbPages = ref.nbPages
                            var pages = ref.pages
                            var isFirstPage = ref.isFirstPage
                            var isLastPage = ref.isLastPage
                            var refine = ref.refine
                            var createURL = ref.createURL
                            return _c(
                              "div",
                              { staticClass: "ais-Pagination" },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "ais-Pagination-list" },
                                  [
                                    isFirstPage
                                      ? _c(
                                          "li",
                                          {
                                            staticClass:
                                              "ais-Pagination-item ais-Pagination-item--previousPage ais-Pagination-item--disabled"
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "ais-Pagination-link"
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      viewBox: "0 0 10 10",
                                                      width: "10",
                                                      height: "10"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "g",
                                                      {
                                                        attrs: {
                                                          fill: "none",
                                                          "fill-rule":
                                                            "evenodd",
                                                          stroke: "#000",
                                                          "stroke-linecap":
                                                            "round",
                                                          "stroke-linejoin":
                                                            "round",
                                                          "stroke-width":
                                                            "1.143"
                                                        }
                                                      },
                                                      [
                                                        _c("path", {
                                                          attrs: {
                                                            d:
                                                              "M9 5H1M5 9L1 5l4-4"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !isFirstPage
                                      ? _c(
                                          "li",
                                          {
                                            staticClass:
                                              "ais-Pagination-item ais-Pagination-item--previousPage"
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "ais-Pagination-link",
                                                attrs: {
                                                  href: createURL(
                                                    currentRefinement - 1
                                                  ),
                                                  "aria-label": "Previous"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return refine(
                                                      currentRefinement - 1
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      viewBox: "0 0 10 10",
                                                      width: "10",
                                                      height: "10"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "g",
                                                      {
                                                        attrs: {
                                                          fill: "none",
                                                          "fill-rule":
                                                            "evenodd",
                                                          stroke: "#000",
                                                          "stroke-linecap":
                                                            "round",
                                                          "stroke-linejoin":
                                                            "round",
                                                          "stroke-width":
                                                            "1.143"
                                                        }
                                                      },
                                                      [
                                                        _c("path", {
                                                          attrs: {
                                                            d:
                                                              "M9 5H1M5 9L1 5l4-4"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._l(pages, function(page) {
                                      return _c(
                                        "li",
                                        {
                                          key: page,
                                          class: _vm.cx(
                                            "ais-Pagination-item",
                                            "ais-Pagination-item--page",
                                            {
                                              "ais-Pagination-item--selected":
                                                page === currentRefinement
                                            }
                                          )
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "ais-Pagination-link",
                                              style: {
                                                fontWeight:
                                                  page === currentRefinement
                                                    ? "bold"
                                                    : ""
                                              },
                                              attrs: { href: createURL(page) },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return refine(page)
                                                }
                                              }
                                            },
                                            [_vm._v(_vm._s(page + 1))]
                                          )
                                        ]
                                      )
                                    }),
                                    _vm._v(" "),
                                    !isLastPage
                                      ? _c(
                                          "li",
                                          {
                                            staticClass:
                                              "ais-Pagination-item ais-Pagination-item--nextPage"
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "ais-Pagination-link",
                                                attrs: {
                                                  href: createURL(
                                                    currentRefinement + 1
                                                  ),
                                                  "aria-label": "Next"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return refine(
                                                      currentRefinement + 1
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      viewBox: "0 0 10 10",
                                                      width: "10",
                                                      height: "10"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "g",
                                                      {
                                                        attrs: {
                                                          fill: "none",
                                                          "fill-rule":
                                                            "evenodd",
                                                          stroke: "#000",
                                                          "stroke-linecap":
                                                            "round",
                                                          "stroke-linejoin":
                                                            "round",
                                                          "stroke-width":
                                                            "1.143"
                                                        }
                                                      },
                                                      [
                                                        _c("path", {
                                                          attrs: {
                                                            d:
                                                              "M1 5h8M5 9l4-4-4-4"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    isLastPage
                                      ? _c(
                                          "li",
                                          {
                                            staticClass:
                                              "ais-Pagination-item ais-Pagination-item--nextPage ais-Pagination-item--disabled"
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "ais-Pagination-link"
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      viewBox: "0 0 10 10",
                                                      width: "10",
                                                      height: "10"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "g",
                                                      {
                                                        attrs: {
                                                          fill: "none",
                                                          "fill-rule":
                                                            "evenodd",
                                                          stroke: "#000",
                                                          "stroke-linecap":
                                                            "round",
                                                          "stroke-linejoin":
                                                            "round",
                                                          "stroke-width":
                                                            "1.143"
                                                        }
                                                      },
                                                      [
                                                        _c("path", {
                                                          attrs: {
                                                            d:
                                                              "M1 5h8M5 9l4-4-4-4"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ]
                            )
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("aside", { attrs: { "data-layout": "mobile" } }, [
            _c(
              "button",
              { staticClass: "filters-button", on: { click: _vm.openFilters } },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewbox: "0 0 16 14"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M15 1H1l5.6 6.3v4.37L9.4 13V7.3z",
                        stroke: "#fff",
                        "stroke-width": "1.29",
                        fill: "none",
                        "fill-rule": "evenodd",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      }
                    })
                  ]
                ),
                _vm._v("\n\n        Filters\n      ")
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }