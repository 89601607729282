<template>
  <div id="root">
    <ais-instant-search
      :search-client="searchClient"
      index-name="dev_users"
      :routing="routing"
    >
      <ais-configure
        :attributesToSnippet="['description:10']"
        snippetEllipsisText="…"
        removeWordsIfNoResults="allOptional"
      />

      <header class="header bg-holder bg-overlay-i justify-content-center text-center pt-3 pt-md-4 pb-9 mb-0" id="header">
              <div class="searchbar-collapse-header">
            <div class="row mt-0 mt-md-4">
                <div class="col-6 collapse-brand pl-md-6 text-left"">
                    <a class="ml-3"  href="#searchbar_global" data-toggle="collapse"
                        data-target="#searchbar_global" aria-controls="searchbar_global" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <img src="https://nimble.careers/assets/img/logo/nimble-logo-round.png" class="img-fluid logo-index" width="40"
                            style="height:auto!important">
                    </a>
                </div>
                <div class="col-6 collapse-close pr-md-6">
                    <a href="#searchbar_global" class="fal fa-times fs-3 text-warning mr-3" data-toggle="collapse"
                        data-target="#searchbar_global" aria-controls="searchbar_global" aria-expanded="false"
                        aria-label="Toggle navigation"></a>
                </div>
            </div>
        </div>
         <div class="text-center w-100">
        <ais-search-box
          placeholder="Type «John Doe»"
        >
          <template slot="submit-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 18 18"
              width="16"
              height="16"
            >
              <g
                fill="none"
                fill-rule="evenodd"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.67"
                transform="translate(1 1)"
              >
                <circle cx="7.11" cy="7.11" r="7.11"></circle>
                <path d="M16 16l-3.87-3.87"></path>
              </g>
            </svg>
          </template>
        </ais-search-box>
        </div>
      </header>

      <main class="container">
        <div class="container-wrapper">
          <section class="container-filters pt-0">
            <div class="container-header">
              <h2>Filters</h2>

              <ais-clear-refinements data-layout="desktop">
                <template slot="resetLabel">
                  <div class="clear-filters">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 11 11"
                    >
                      <g fill="none" fill-rule="evenodd" opacity=".4">
                        <path d="M0 0h11v11H0z"></path>
                        <path
                          fill="#000"
                          fill-rule="nonzero"
                          d="M8.26 2.75a3.896 3.896 0 1 0 1.102 3.262l.007-.056a.49.49 0 0 1 .485-.456c.253 0 .451.206.437.457 0 0 .012-.109-.006.061a4.813 4.813 0 1 1-1.348-3.887v-.987a.458.458 0 1 1 .917.002v2.062a.459.459 0 0 1-.459.459H7.334a.458.458 0 1 1-.002-.917h.928z"
                        ></path>
                      </g>
                    </svg>
                    Clear filters
                  </div>
                </template>
              </ais-clear-refinements>

              <ais-stats data-layout="mobile">
                <template slot="default" slot-scope="{ nbHits }">
                  <span class="ais-Stats-text">
                    <strong>{{ formatNumber(nbHits) }}</strong> results
                  </span>
                </template>
              </ais-stats>
            </div>

            <div class="container-body">
              <ais-panel>
                <template slot="header">Career Availability</template>

                <template slot="default">
                  <ais-refinement-list
                    attribute="jobPreference.jobAvailability.shortLabel"
                  />
                </template>
                   </ais-panel>
                            <ais-panel>
                <template slot="header">Career Industry</template>

                <template slot="default">
                  <ais-refinement-list
                    attribute="jobPreference.jobField.label"

                    searchable
                    searchablePlaceholder="Search for Industry"
                  />
                </template>
              </ais-panel>
              </ais-panel>
                            <ais-panel>
                <template slot="header">Job title</template>

                <template slot="default">
                  <ais-refinement-list
                    attribute="jobPreference.jobTitle.label"

                    searchable
                    searchablePlaceholder="Search for title"
                  />
                </template>
              </ais-panel>
            
              <ais-panel>
                <template slot="header">Contract Types</template>

                <template slot="default">
                  <ais-refinement-list
                    attribute="jobPreference.jobType.label"

                    searchable
                    searchablePlaceholder="Search for contract type"
                  />
                </template>
              </ais-panel>
               <ais-panel>
                <template slot="header">Preferred Location</template>

                <template slot="default">
                  <ais-refinement-list
                    attribute="jobPreference.jobLocation.label"

                    searchable
                    searchablePlaceholder="Search for location"
                  />
                </template>
              </ais-panel>
             
              <ais-panel>
                <template slot="header">Schools</template>

                <template slot="default">
                  <ais-refinement-list
                    attribute="graduateUser.school.name"
                    searchable
                    searchablePlaceholder="Search for school…"
                  />
                </template>
              </ais-panel>
              <ais-panel>
                <template slot="header">Fields of study</template>
                 <template slot="default">
                  <ais-refinement-list
                  attribute="graduateUser.degreeFields.label"
                  searchable
                    searchablePlaceholder="Search for fields…"
                  />
                </template>
              </ais-panel>
              <ais-panel>
                <template slot="header">Curriculum Title</template>
                 <template slot="default">
                  <ais-refinement-list
                  attribute="graduateUser.degrees.degreeTitle.label"
                  searchable
                    searchablePlaceholder="Search for curriculum"
                  />
                </template>
              </ais-panel
              <ais-panel>
                <template slot="header">Year-end Graduation</template>

                <template slot="default">

                   <ais-range-input attribute="graduateUser.issuances.algoliasYearEnd">
                    <div slot-scope="{ currentRefinement, range, refine }">
                      <vue-slider
                        :min="range.min"
                        :max="range.max"
                        :lazy="true"
                        :value="toValue(currentRefinement, range)"
                        @change="refine({ min: $event[0], max: $event[1] })"
                      />
                    </div>
                  </ais-range-input>
                </template>
              </ais-panel>

              <ais-panel>
                <template slot="header">Honors</template>

                <template slot="default">
                  <ais-refinement-list
                    attribute="graduateUser.degrees.honor"
                  />
                </template>
              </ais-panel>
            </div>
          </section>

          <footer class="container-filters-footer" data-layout="mobile">
            <clear-refinements
              class="container-filters-footer-button-wrapper"
              @click="closeFilters"
            />

            <ais-stats
              class="container-filters-footer-button-wrapper"
            >
              <template slot="default" slot-scope="{ nbHits }">
                <button class="button button-primary" @click="closeFilters">
                  See {{ formatNumber(nbHits) }} results
                </button>
              </template>
            </ais-stats>
          </footer>
        </div>

        <section class="container-results pt-0">
          <header class="container-header container-options mr-3">
                <ais-hits-per-page
                  class="container-option text-primary"
                  :items="[
                    {
                      label: '16 alumni per page',
                      value: 16,
                      default: getSelectedHitsPerPageValue() === 16 || !getSelectedHitsPerPageValue(),
                    },
                    {
                      label: '32 alumni per page',
                      value: 32,
                      default: getSelectedHitsPerPageValue() === 32,
                    },
                    {
                      label: '64 alumni per page',
                      value: 64,
                      default: getSelectedHitsPerPageValue() === 64,
                    },
                  ]"
            />
          </header>

          <ais-hits>
            <div slot="item" slot-scope="{ item }">
              

                  <div class="card mt-0 mr-0 ml-0 py-2" style="min-height:170px">
                   <a :href="'/' + item.objectID">
                      <div class="card-body justify-content-between w-100">
                          <div class="row pt-2">
                              <div class="col-4">
                                    
                                <div v-if="item.picture.length < 2" class="img-fluid rounded mb-2 img-profile mt-2 bg-warning align-items-center justify-content-center d-flex square h-100">
                                    <p class='text-center text-white fs-4'>
                                      <ais-highlight attribute="picture"  :hit="item"/>
                                    </p>
                                </div>
                                <img v-else v-bind:src="'https://app.nimble.careers/uploads/picture/' + item.picture"  :alt="item.name" class="hit-image rounded">
                                  
                              </div>

                              <div class="col-8">
                                  <div class="hit-info-container">
                                     
                                          <h5 class='text-secondary font-weight-bold'>
                                          <ais-highlight attribute="firstname" :hit="item"/>
                                          <ais-highlight attribute="lastname" :hit="item"/>
                                              </h5>
                                     
                                      <span class="text-muted"><ais-highlight attribute="graduateUser.degrees.degreeTitle.label[0]" :hit="item"/></span>
                                      <div class="mt-1">
                                          <i class="fal fa-map-marker text-warning font-weight-normal"></i>
                                        <ais-highlight attribute="location" :hit="item"/>
                                        
                                      </div>
                                      <p class="font-weight-bold mt-2">
                                      <ais-highlight attribute="careerField.label" :hit="item"/>
                                      <span
                                              class="badge badge-primary"><ais-highlight attribute="jobPreference.jobAvailability.shortLabel" :hit="item"/></span></p>
                                  </div>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col-12">
                                

                              </div>

                          </div>
                      </div>
                       </a>
                  </div>


            </div>
          </ais-hits>

          <no-results />

          <footer class="container-footer">
            <ais-pagination :padding="2">
              <div
                slot-scope="{
                  currentRefinement,
                  nbPages,
                  pages,
                  isFirstPage,
                  isLastPage,
                  refine,
                  createURL
                }"
                class="ais-Pagination"
              >
                <ul class="ais-Pagination-list">
                  <li
                    class="ais-Pagination-item ais-Pagination-item--previousPage ais-Pagination-item--disabled"
                    v-if="isFirstPage"
                  >
                    <span class="ais-Pagination-link">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 10 10"
                        width="10"
                        height="10"
                      >
                        <g
                          fill="none"
                          fill-rule="evenodd"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.143"
                        >
                          <path d="M9 5H1M5 9L1 5l4-4"></path>
                        </g>
                      </svg>
                    </span>
                  </li>

                  <li
                    class="ais-Pagination-item ais-Pagination-item--previousPage"
                    v-if="!isFirstPage"
                  >
                    <a
                      class="ais-Pagination-link"
                      :href="createURL(currentRefinement - 1)"
                      @click.prevent="refine(currentRefinement - 1)"
                      aria-label="Previous"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 10 10"
                        width="10"
                        height="10"
                      >
                        <g
                          fill="none"
                          fill-rule="evenodd"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.143"
                        >
                          <path d="M9 5H1M5 9L1 5l4-4"></path>
                        </g>
                      </svg>
                    </a>
                  </li>

                  <li
                    :class="cx('ais-Pagination-item', 'ais-Pagination-item--page', {
                      'ais-Pagination-item--selected': page === currentRefinement
                    })"
                    v-for="page in pages"
                    :key="page"
                  >
                    <a
                      class="ais-Pagination-link"
                      :href="createURL(page)"
                      :style="{ fontWeight: page === currentRefinement ? 'bold' : '' }"
                      @click.prevent="refine(page)"
                    >{{ page + 1 }}</a>
                  </li>

                  <li class="ais-Pagination-item ais-Pagination-item--nextPage" v-if="!isLastPage">
                    <a
                      class="ais-Pagination-link"
                      :href="createURL(currentRefinement + 1)"
                      @click.prevent="refine(currentRefinement + 1)"
                      aria-label="Next"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 10 10"
                        width="10"
                        height="10"
                      >
                        <g
                          fill="none"
                          fill-rule="evenodd"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.143"
                        >
                          <path d="M1 5h8M5 9l4-4-4-4"></path>
                        </g>
                      </svg>
                    </a>
                  </li>

                  <li
                    class="ais-Pagination-item ais-Pagination-item--nextPage ais-Pagination-item--disabled"
                    v-if="isLastPage"
                  >
                    <span class="ais-Pagination-link">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 10 10"
                        width="10"
                        height="10"
                      >
                        <g
                          fill="none"
                          fill-rule="evenodd"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.143"
                        >
                          <path d="M1 5h8M5 9l4-4-4-4"></path>
                        </g>
                      </svg>
                    </span>
                  </li>
                </ul>
              </div>
            </ais-pagination>
          </footer>
        </section>
      </main>

      <aside data-layout="mobile">
        <button class="filters-button" @click="openFilters">
          <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 16 14">
            <path
              d="M15 1H1l5.6 6.3v4.37L9.4 13V7.3z"
              stroke="#fff"
              stroke-width="1.29"
              fill="none"
              fill-rule="evenodd"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          Filters
        </button>
      </aside>
    </ais-instant-search>
  </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';
import VueSlider from 'vue-slider-component';
import cx from 'classnames';
import ClearRefinements from './widgets/ClearRefinements.vue';
import NoResults from './widgets/NoResults.vue';
import { formatNumber } from './utils';
import routing from './routing';
import './Theme.css';
import './../css/algolia-min.css';
import './App.css';
import './App.mobile.css';
import './widgets/PriceSlider.css';
export default {
  components: {
    VueSlider,
    ClearRefinements,
    NoResults
  },
  created() {
    this.onKeyUp = event => {
      if (event.key !== 'Escape') {
        return;
      }
      this.closeFilters();
    }
    this.onClick = event => {
      if (event.target !== this.header) {
        return;
      }
      this.closeFilters();
    }
  },
  mounted() {
    this.resultsContainer = document.querySelector('.container-results');
    this.header = document.querySelector('#header');
  },
  data() {
    return {
      cx,
      searchClient: algoliasearch(
        '1T4BN8337B',
        '62129ceb388cb2bda5ae59baa4ff9c38'
      ),
      routing
    };
  },
  methods: {
    formatNumber,
    toValue(value, range) {
      return [
        value.min !== null ? value.min : range.min,
        value.max !== null ? value.max : range.max,
      ];
    },
    getSelectedHitsPerPageValue() {
      const [, hitsPerPage] = document.location.search.match(/hitsPerPage=([0-9]+)/) || [];
      return Number(hitsPerPage);
    },
    openFilters() {
      document.body.classList.add('filtering');
      window.scrollTo(0, 0);
      window.addEventListener('keyup', this.onKeyUp);
      window.addEventListener('click', this.onClick);
    },
    closeFilters() {
      document.body.classList.remove('filtering');
      this.resultsContainer.scrollIntoView();
      window.removeEventListener('keyup', this.onKeyUp);
      window.removeEventListener('click', this.onClick);
    },
  },
};
</script>