var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.state && _vm.state.results && _vm.state.results.nbHits === 0
    ? _c("div", [
        _c(
          "div",
          { staticClass: "hits-empty-state" },
          [
            _c("p", { staticClass: "hits-empty-state-title" }, [
              _vm._v(
                "\n      Sorry, we can't find any matches to your query!\n    "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "hits-empty-state-description" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.state.results.getRefinements().length > 0
                      ? "Try to reset your applied filters."
                      : "Please try another query."
                  ) +
                  "\n    "
              )
            ]),
            _vm._v(" "),
            _c(
              "ais-clear-refinements",
              [
                _c("template", { slot: "resetLabel" }, [
                  _c("div", { staticClass: "clear-filters" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "11",
                          height: "11",
                          viewBox: "0 0 11 11"
                        }
                      },
                      [
                        _c(
                          "g",
                          { attrs: { fill: "none", "fill-rule": "evenodd" } },
                          [
                            _c("path", { attrs: { d: "M0 0h11v11H0z" } }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                fill: "#000",
                                "fill-rule": "nonzero",
                                d:
                                  "M8.26 2.75a3.896 3.896 0 1 0 1.102 3.262l.007-.056a.49.49 0 0 1 .485-.456c.253 0 .451.206.437.457 0 0 .012-.109-.006.061a4.813 4.813 0 1 1-1.348-3.887v-.987a.458.458 0 1 1 .917.002v2.062a.459.459 0 0 1-.459.459H7.334a.458.458 0 1 1-.002-.917h.928z"
                              }
                            })
                          ]
                        )
                      ]
                    ),
                    _vm._v("\n          Clear filters\n        ")
                  ])
                ])
              ],
              2
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }